import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  // Testimonial,
  Services,
  // Clients,
  ContactUs,
  CaseStudies,
  TechStack,
} from "@Components/Home";
import HowWeWork from "@Components/HowWeWork";
// @ts-ignore
// tslint:disable-next-line: import-name
import heroImage from "@Images/website_setup_new.svg";
import "@styles/home.scss";

const titleString = ["Javascript", "React", "React Native", "Node.js"];

function IndexPage() {
  const [activeTitle, setActiveTitle] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTitle((activeTitle + 1) % titleString.length);
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, [activeTitle]);

  return (
    <>
      <header className="head" data-page="home">
        <div className="container hero">
          <div className="hero-title">
            <h1 id="hero-heading">
              The
              <TransitionGroup className="subtitleAnimate">
                <CSSTransition
                  key={activeTitle}
                  timeout={500}
                  classNames="subtitle"
                >
                  <span id="head-title">{titleString[activeTitle]}</span>
                </CSSTransition>
              </TransitionGroup>
              development expretise
            </h1>
            <h3 id="hero-description">
              Fregmaa is a modern development studio, we build solid web
              applications using cutting-edge technologies.
            </h3>
            <div className="call-to-action">
              <a href="#contact-us" className="cta-btn">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </header>
      <div id="hero-image">
        <img
          id="image__hero"
          draggable={false}
          src={heroImage}
          alt="Home Page Illustration"
        />
      </div>
      <section id="techStack">
        <TechStack />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="feature-set">
        <CaseStudies />
      </section>
      <HowWeWork />
      {/* <section className="section__testimonial">
      <Testimonial />
    </section>
    <section className="section__clients">
      <Clients />
    </section> */}
      <section className="section__contact-us">
        <ContactUs />
      </section>
    </>
  );
}

export default IndexPage;
