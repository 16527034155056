import React, { useState, useEffect } from "react";
import { navigate } from "gatsby-link";
import "./styles/_contactUs.scss";

import useScreenObserver from "@Utils/Observer";

function encode(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function contactUs() {
  const [visible, setRef] = useScreenObserver({ rootMargin: "-100px" });

  const [state, setState] = useState({});

  const handleChange = (e: React.ChangeEvent<any>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (visible && setRef.current) {
      setRef.current.classList.add("heading-animation");
    }
  }, [setRef, visible]);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  return (
    <div className="container">
      <div className="contactUs" id="contact-us">
        <h2 ref={setRef} className="contactus__heading">
          Launching your <span className="color-primary">Next Project</span>?
          Let's Talk!
        </h2>
      </div>
      <div className="form_container">
        <form
          name="contact"
          method="POST"
          className="contact__form"
          action="/thankyou/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleOnClick}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <label className="contact__form-label">
            <input
              className="contact__form-input"
              placeholder="Business / Your Name"
              aria-label="Business Name"
              type="text"
              name="name"
              onChange={handleChange}
            />
          </label>
          <label className="contact__form-label">
            <input
              className="contact__form-input"
              placeholder="Business Email"
              aria-label="Business Email"
              type="email"
              name="email"
              onChange={handleChange}
            />
          </label>
          <label className="contact__form-label">
            <textarea
              className="contact__form-input-text"
              placeholder="Your Message"
              aria-label="Message"
              name="message"
              onChange={handleChange}
            />
          </label>
          <button className="contact__form-btn" type="submit">
            Get Started
          </button>
        </form>
      </div>
    </div>
  );
}

export default contactUs;
