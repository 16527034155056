import React from "react";

import ReactLogo from "static/images/vendor_logos/React-icon.svg";
import NodeLogo from "static/images/vendor_logos/Node.js_logo.svg";
import JSLogo from "static/images/vendor_logos/JavaScript_logo.svg";
import GoLogo from "static/images/vendor_logos/Go_Logo.svg";
import GraphQLLogo from "static/images/vendor_logos/GraphQL_Logo.svg";

function TechStack() {
  return (
    <div className="tech-stack container">
      <ul className="techList">
        <li className="techItem">
          <img src={ReactLogo} alt="React" />
        </li>
        <li className="techItem">
          <img src={JSLogo} alt="Javascript" id="Short" />
        </li>
        <li className="techItem">
          <img src={NodeLogo} alt="Node" />
        </li>
        <li className="techItem">
          <img src={GoLogo} alt="GoLang" />
        </li>
        <li className="techItem">
          <img src={GraphQLLogo} alt="GraphQL" id="Short" />
        </li>
      </ul>
    </div>
  );
}

export default TechStack;
