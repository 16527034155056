import React from "react";
import { Link } from "gatsby";

import StarClient from "@Images/client_logos/StarPlus.png";
import SonyClient from "@Images/client_logos/Sony_Sab.jpg";
import ColorsClient from "@Images/client_logos/Colorslogo.png";
import GitanjaliClient from "@Images/client_logos/Gitanjali-Gems-logo-design.png";
import MaricoClient from "@Images/client_logos/Marico-Logo-india.png";

function Clients() {
  return (
    <>
      <div className="container clients">
        <div className="clients__head">
          <h2 className="clients__head-title">
            Trusted by the <span>Enterprise</span>
          </h2>
          <div className="clients__images">
            <img
              className="clients__images-img"
              src={StarClient}
              alt="Client Logo"
            />
            <img
              className="clients__images-img"
              src={GitanjaliClient}
              alt="Client Logo"
            />
            <img
              className="clients__images-img"
              src={SonyClient}
              alt="Client Logo"
            />
            <img
              className="clients__images-img"
              src={MaricoClient}
              alt="Client Logo"
            />
            <img
              className="clients__images-img"
              src={ColorsClient}
              alt="Client Logo"
            />
          </div>
          <Link to="/clients" className="clients__btn">
            Our Clients 🡪
          </Link>
        </div>
      </div>
    </>
  );
}
export default Clients;
