import React from "react";
import { Link } from "gatsby";

// @ts-ignore
import servicesFrontEnd from "@Images/service_react.svg";
// @ts-ignore
import servicesMobile from "@Images/Mobile_application.svg";
// @ts-ignore
import servicesBackend from "@Images/service_backend.svg";

function Services() {
  return (
    <div className="container services">
      <div className="services__head">
        <div className="services__tag">Services</div>
        <div className="services__heading">
          <h2 className="section-heading">
            What
            <span className="sub-head"> we </span>
            do
          </h2>
          <h3 className="section-heading__sub">
            We are Javascript developers who love challenges and we thrive to
            solve complex problems. We pledge to deliver solid application ready
            to scale and perform.
          </h3>
        </div>
      </div>
      <div className="services__list">
        <div className="services__list-item">
          <img
            src={servicesFrontEnd}
            draggable={false}
            alt="Frontend Services"
            className="services__list-img"
          />
          <h4 className="services__list-item--heading">Frontend</h4>
          <ul className="services__list-services">
            <li className="services__list-services--item">
              <Link to="/services/frontend#react">React Development</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/frontend#pwa">PWA Application</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/frontend#auditing">Performace Auditing</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/frontend">Learn More on Frontend</Link>
            </li>
          </ul>
        </div>
        <div className="services__list-item">
          <img
            src={servicesMobile}
            draggable={false}
            alt="Mobile Services"
            className="services__list-img"
          />
          <h4 className="services__list-item--heading">Mobile</h4>
          <ul className="services__list-services">
            <li className="services__list-services--item">
              <Link to="/services/mobile#reactnative">
                React Native Development
              </Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/mobile#uiux">UI/UX</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/mobile">Learn More on Mobile</Link>
            </li>
          </ul>
        </div>
        <div className="services__list-item">
          <img
            src={servicesBackend}
            draggable={false}
            alt="Backend Services"
            className="services__list-img"
          />
          <h4 className="services__list-item--heading">Backend</h4>
          <ul className="services__list-services">
            <li className="services__list-services--item">
              <Link to="/services/backend#node">Node.js</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/backend#graphql">GraphQL</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/backend#mongodb">MongoDB/ PostgreSQL</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/backend#auditing">System Architecture</Link>
            </li>
            <li className="services__list-services--item">
              <Link to="/services/backend">Learn More on Backend</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;
